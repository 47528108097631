<template>
	<main>
		<div class="column__wrapper">
			<div class="column column__left">
				<AccountMenu :activeLink="'address'" />
			</div>
			<div class="column column__right">
				<h2>Modifier le contact :  {{ partnerForm.name }}</h2>
				<form method="post" id="contact_form" @submit.pr.prevent="UpdateRespartner">

					<div class="form__row">
						<div class="form__row__item" :class="partnerForm.last_name ? 'valid' : 'not-valid'">
							<input
								type="text"
								id="last_name"
								name="last_name"
								v-model="partnerForm.last_name"
								class="input"
								:placeholder="partnerForm.last_name ? partnerForm.last_name : 'NOM'"
								required
							/>
							<label for="last_name">Nom</label>
						</div>
						<div class="form__row__item" :class="partnerForm.first_name ? 'valid' : 'not-valid'">
							<input
								type="text"
								id="first_name"
								name="first_name"
								v-model="partnerForm.first_name"
								class="input"
								:placeholder="partnerForm.first_name ? partnerForm.first_name : 'Prénom'"
								required
							/>
							<label for="first_name">Prénom</label>
						</div>
					</div>

					<div class="form__row">
						<div class="form__row__item" :class="partnerForm.email ? 'valid' : 'not-valid'">
							<input
								type="email"
								id="email"
								name="email"
								v-model="partnerForm.email"
								class="input"
								:placeholder="partnerForm.email ? partnerForm.email : 'Email'"
								required
							/>
							<label for="email">Adresse e-mail</label>
						</div>
					</div>

					<div class="form__row">
						<div class="form__row__item" :class="partnerForm.phone ? 'valid' : 'not-valid'">
							<input
								type="text"
                pattern="((\+|00)?[1-9]{2}|0)[1-9]( ?[0-9]){8}"
								id="phone"
								name="phone"
								v-model="partnerForm.phone"
								class="input"
								:placeholder="partnerForm.phone ? partnerForm.phone : 'Téléphone'"
								required
							/>
							<label for="phone">Téléphone</label>
						</div>
					</div>

					<div class="form__row">
						<div class="form__row__item" :class="partnerForm.street ? 'valid' : 'not-valid'">
              <AddressAutocomplete id="street"
                                   :placeholder="partnerForm.street +' '+ partnerForm.zip +' '+ partnerForm.city"
                                   classname="input"
                                   :set-location="false"
                                   v-on:placechanged="getAddressData"
              ></AddressAutocomplete>
<!--							<input-->
<!--								type="text"-->
<!--								id="street"-->
<!--								name="street"-->
<!--								ref="streetRef"-->
<!--								v-model="partnerForm.street"-->
<!--								class="input"-->
<!--								:placeholder="partnerForm.street ? partnerForm.street : 'Rue'"-->
<!--								required-->
<!--							/>-->
							<label >Adresse</label>
						</div>
					</div>

					<div class="form__row">
						<div class="form__row__item" :class="partnerForm.street2 ? 'valid' : 'not-valid'">

							<input
								type="text"
								id="street2"
								name="street2"
								v-model="partnerForm.street2"
								class="input"
								:placeholder="partnerForm.street2 ? partnerForm.street2 : 'Complément d\'adresse'"
							/>
							<label>Complément d'adresse</label>
						</div>
					</div>

<!--					<div class="form__row">-->
<!--						<div class="form__row__item" :class="partnerForm.zip ? 'valid' : 'not-valid'">-->
<!--							<input-->
<!--								type="text"-->
<!--								id="zip"-->
<!--								name="zip"-->
<!--								v-model="partnerForm.zip"-->
<!--								class="input"-->
<!--								:placeholder="partnerForm.zip ? partnerForm.zip : 'Code Postale'"-->
<!--								required-->
<!--							/>-->
<!--							<label for="zip">Code Postale</label>-->
<!--						</div>-->
<!--					</div>-->

<!--					<div class="form__row">-->
<!--						<div class="form__row__item" :class="partnerForm.city ? 'valid' : 'not-valid'">-->
<!--							<input-->
<!--								type="text"-->
<!--								id="city"-->
<!--								name="city"-->
<!--								v-model="partnerForm.city"-->
<!--								class="input"-->
<!--								:placeholder="partnerForm.city ? partnerForm.city : 'Ville'"-->
<!--								required-->
<!--							/>-->
<!--							<label for="city">Ville</label>-->
<!--						</div>-->
<!--					</div>-->

					<div class="form__row">
						<button aria-label="save" type="submit" class="button full"><span>Enregistrer</span></button>
						<button  aria-label="back" class="button" @click="$router.go(-1)"><span>Retour</span></button>
					</div>
				</form>
			</div>
		</div>
	</main>
</template>

<script>
import {getAPI} from "@/services/axios-api";
import AccountMenu from "@/components/AccountMenu.vue";
import AddressAutocomplete from "@/components/AddressAutocomplete";

export default {
	name: "EditAddress",
	components: {AccountMenu, AddressAutocomplete},
	data() {
		return {
			nb: null,
			str: null,
			partnerForm: [],
		}
	},
	mounted() {
		this.getpartner();
	},
	methods: {
    getAddressData: function (addressData, placeResultData, id) {
      this.partnerForm.street = addressData.street;
      this.partnerForm.city = addressData.city;
      this.partnerForm.zip = addressData.zip;
      this.partnerForm.country = addressData.country;
      this.partnerForm.latitude = addressData.latitude;
      this.partnerForm.longitude = addressData.longitude;
    },
		async getpartner() {
			await getAPI
				.get('/respartner/' + this.$attrs.id + '/')
				.then(response => {
					this.partnerForm = response.data;
				})
				.catch(error => console.log(error.data))

		},

		async UpdateRespartner() {
			this.partnerForm.last_name = this.partnerForm.last_name.toUpperCase()
			this.partnerForm.first_name = this.partnerForm.first_name[0].toUpperCase() + this.partnerForm.first_name.slice(1)
			const formData = this.partnerForm;
			await getAPI
				.patch('/respartner/' + this.$attrs.id + '/', {
					'data': formData, 'update': 'partner'
				})
				.then(response => {
					if (response.status === 200) {
						this.$toast.open({
							message: response.data.success,
							type: 'success',
						});
            this.$router.push({name: "Address"})
					} else {
						this.$toast.open({
							message: response.data.error,
							type: 'error',
						});
					}
				})
				.catch(error => console.log(error.data))
		},
	},
}
</script>

<style lang="scss" scoped>

form {

	.form__row__item.valid {
		.input {
			color: $Black;

			&::placeholder {
				color: $Black;
			}
		}
	}

}

</style>