<template>
  <input
      ref="autocomplete"
      type="text"
      :class="classname"
      :id="id"
      :placeholder="placeholder"
      v-model="autocompleteText"
      @focus="onFocus()"
      @blur="onBlur()"
      @keypress="onKeyPress"
      @keyup="onKeyUp"
  />
</template>

<script>
export default {
  name: "AddressAutocomplete",
  props: {
    id: {
      type: String,
      required: true
    },
    classname: String,
    placeholder: {
      type: String,
      default: 'adresse ou code postal'
    },
    types: {
      type: String,
      default: 'address'
    },
    setLocation: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      fields: ['address_components', 'adr_address', 'alt_id',
        'formatted_address', 'geometry', 'icon', 'id', 'name',
        'business_status', 'photo', 'place_id', 'scope', 'type', 'url',
        'utc_offset_minutes', 'vicinity'],
      autocomplete: null,
      autocompleteText: '',
      geolocation: {
        geocoder: null,
        loc: null,
        position: null
      }
    }
  },
  watch: {
    autocompleteText: function (newVal, oldVal) {
      this.$emit('inputChange', {newVal, oldVal}, this.id);
    },
  },
  mounted: function () {
    const options = {
      componentRestrictions: {country: ["fr", "ch", "be"]},
      fields: ["address_components", "geometry"],
    };
    this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById(this.id),
        options
    );
    this.autocomplete.setFields(this.fields);
    this.autocomplete.addListener('place_changed', this.onPlaceChanged);
  },
  methods: {
    onPlaceChanged: function () {
      let place = this.autocomplete.getPlace();
      if (!place.geometry) {
        this.$emit('no-results-found', place, this.id);
        return;
      }
      if (place.address_components !== undefined) {
        const formatted_place = this.formatResult(place)
        if (this.setLocation) this.$store.dispatch('auth/setLocation', formatted_place);
        this.$emit('placechanged', formatted_place, place, this.id);
        this.autocompleteText = document.getElementById(this.id).value
        this.onChange()
      }
    },
    formatResult(place) {
      const ADDRESS_COMPONENTS = {
        subpremise: {type: 'short_name', name: 'subpremise'},
        street_number: {type: 'short_name', name: 'street_number'},
        route: {type: 'long_name', name: 'route'},
        street: {type: 'long_name', name: 'streetg'},
        colloquial_area: {type: 'long_name', name: 'colloquial_area'},
        locality: {type: 'long_name', name: 'city'},
        administrative_area_level_1: {type: 'short_name', name: 'administrative_area_level_1'},
        administrative_area_level_2: {type: 'long_name', name: 'administrative_area_level_2'},
        country: {type: 'short_name', name: 'country'},
        postal_code: {type: 'short_name', name: 'zip'}
      }
      let returnData = {};
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];
        if (ADDRESS_COMPONENTS[addressType] && ADDRESS_COMPONENTS[addressType].type) {
          let val = place.address_components[i][ADDRESS_COMPONENTS[addressType].type];
          returnData[ADDRESS_COMPONENTS[addressType].name] = val;
        }
      }
      if (returnData["route"]) {
        returnData['street'] = returnData["street_number"] ? (returnData["street_number"] + ' '+ returnData["route"]) :  returnData["route"];
      } else if (returnData["streetg"]) {
        returnData['street'] = returnData["street_number"] ? (returnData["street_number"] + ' '+ returnData["streetg"]) :  returnData["streetg"];
      } else if (returnData["colloquial_area"]) {
        returnData['street'] = returnData["colloquial_area"]
      }
      returnData['latitude'] = place.geometry.location.lat();
      returnData['longitude'] = place.geometry.location.lng();
      return returnData
    },
    onFocus() {
      this.$emit('focus');
    },
    onBlur() {
      this.$emit('blur');
    },
    onChange() {
      this.$emit('change', this.autocompleteText);
    },
    onKeyPress(event) {
      this.$emit('keypress', event);
    },
    onKeyUp(event) {
      this.$emit('keyup', event);
    },
    clear() {
      this.autocompleteText = ''
    },
    focus() {
      this.$refs.autocomplete.focus()
    },
    blur() {
      this.$refs.autocomplete.blur()
      this.$emit('blur')
    },
    update(value) {
      this.autocompleteText = value
    },

  },
}
</script>

<style lang="scss">

    .pac-target-input{
    }
    .pac-item-query{
    }
    .pac-container, .pac-item {
      width: auto !important;
      max-width: none !important;
    }


</style>